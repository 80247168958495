// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { createSelector } from 'reselect';
/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  setCurrentStep: ['payload'],
  setRecordVideoData: ['payload'],
  setRecord360VideoData: ['payload'],
  setVinCaptureData: ['payload'],
  setOdoCaptureData: ['payload'],
  setDmgCaptureData: ['payload'],
  setZoomInFramesData: ['payload'],
  setLogData: ['payload'],
  setTransitionAndVideo: ['payload'],
  setIsFullRecordVideoData: ['payload'],

  handleFinishCase: [],
  handleFinishCaseSuccess: ['payload'],
  handleFinishCaseFailure: ['payload'],

  getCases: [],
  getCasesSuccess: ['payload'],
  getCasesFailure: ['payload'],

  getCaseDetail: ['id', 'callback'],
  getCaseDetailSuccess: ['payload'],
  getCaseDetailFailure: ['payload'],

  createCase: [],
  createCaseSuccess: ['payload'],
  createCaseFailure: ['payload'],

  redoCaseDetail: ['id'],
  redoCaseDetailFailure: ['payload'],
});

export const INITIAL_STATE = Immutable({
  currentStep: 0,
  recordVideoData: {
    status: 'Pending',
    value: '',
  },
  record360VideoData: {
    status: 'Pending',
    value: '',
  },
  vinCaptureData: {
    status: 'Pending',
    value: '',
  },
  odoCaptureData: {
    status: 'Pending',
    value: '',
  },
  dmgCaptureData: {
    status: 'Pending',
    value: {
      zoomIn: '',
      zoomOut: '',
    },
  },
  zoomInFramesData: {
    status: 'Pending',
    value: [],
  },
  log: [],
  transitionAndVideo: {
    transition: [],
    video: '',
  },
  loading: false,
  error: null,
  cases: [],
  isFullRecordVideoData: false,
  currentCase: null,
  appMode: process.env.REACT_APP_MODE,
});

const setCurrentStep = (state = INITIAL_STATE, { payload }) => {
  const { currentStep } = payload;
  return { ...state, currentStep };
};

const setRecordVideoData = (state = INITIAL_STATE, { payload }) => {
  return { ...state, recordVideoData: payload };
};

const setRecord360VideoData = (state = INITIAL_STATE, { payload }) => {
  return { ...state, record360VideoData: payload };
};

const setVinCaptureData = (state = INITIAL_STATE, { payload }) => {
  return { ...state, vinCaptureData: payload };
};

const setOdoCaptureData = (state = INITIAL_STATE, { payload }) => {
  return { ...state, odoCaptureData: payload };
};

const setDmgCaptureData = (state = INITIAL_STATE, { payload }) => {
  return { ...state, dmgCaptureData: payload };
};

const setZoomInFramesData = (state = INITIAL_STATE, { payload }) => {
  return { ...state, zoomInFramesData: payload };
};

const setLogData = (state = INITIAL_STATE, { payload }) => {
  return { ...state, log: payload };
};

const setTransitionAndVideo = (state = INITIAL_STATE, { payload }) => {
  return { ...state, transitionAndVideo: payload };
};

const setIsFullRecordVideoData = (state = INITIAL_STATE, { payload }) => {
  return { ...state, isFullRecordVideoData: payload.data };
};

const upload = (state = INITIAL_STATE) => {
  return { ...state, loading: true, error: null };
};

const uploadSuccess = (state = INITIAL_STATE) => {
  return { ...INITIAL_STATE, currentStep: state.currentStep };
};

const uploadFailure = (state = INITIAL_STATE, { payload }) => {
  return { ...state, loading: false, error: payload };
};

const getCases = (state = INITIAL_STATE) => {
  return { ...state, loading: true, error: null };
};

const getCasesSuccess = (state = INITIAL_STATE, { payload }) => {
  return { ...state, loading: false, cases: payload };
};

const getCasesFailure = (state = INITIAL_STATE, { payload }) => {
  return { ...state, loading: false, error: payload };
};

const getCaseDetail = (state = INITIAL_STATE) => {
  return { ...state, loading: true, error: null };
};

const getCaseDetailSuccess = (state = INITIAL_STATE, { payload }) => {
  return { ...state, loading: false, currentCase: payload };
};

const getCaseDetailFailure = (state = INITIAL_STATE, { payload }) => {
  return { ...state, loading: false, error: payload };
};

const redoCaseDetail = (state = INITIAL_STATE) => {
  return { ...state, loading: true, error: null };
};

const redoCaseDetailFailure = (state = INITIAL_STATE, { payload }) => {
  return { ...state, loading: false, error: payload };
};

// const createCase = (state = INITIAL_STATE) => {
//   return { ...state, loading: true, error: null };
// };

// const createCaseSuccess = (state = INITIAL_STATE, { payload }) => {
//   return { ...state, loading: false, case: payload };
// };

// const createCaseFailure = (state = INITIAL_STATE, { payload }) => {
//   return { ...state, loading: false, error: payload };
// };

export const CaseTypes = Types;

export const caseReducer = createReducer(INITIAL_STATE, {
  [Types.SET_CURRENT_STEP]: setCurrentStep,

  [Types.SET_RECORD_VIDEO_DATA]: setRecordVideoData,
  [Types.SET_RECORD360_VIDEO_DATA]: setRecord360VideoData,
  [Types.SET_VIN_CAPTURE_DATA]: setVinCaptureData,
  [Types.SET_ODO_CAPTURE_DATA]: setOdoCaptureData,
  [Types.SET_DMG_CAPTURE_DATA]: setDmgCaptureData,
  [Types.SET_ZOOM_IN_FRAMES_DATA]: setZoomInFramesData,
  [Types.SET_LOG_DATA]: setLogData,
  [Types.SET_TRANSITION_AND_VIDEO]: setTransitionAndVideo,
  [Types.SET_IS_FULL_RECORD_VIDEO_DATA]: setIsFullRecordVideoData,

  [Types.HANDLE_FINISH_CASE]: upload,
  [Types.HANDLE_FINISH_CASE_SUCCESS]: uploadSuccess,
  [Types.HANDLE_FINISH_CASE_FAILURE]: uploadFailure,

  [Types.GET_CASES]: getCases,
  [Types.GET_CASES_SUCCESS]: getCasesSuccess,
  [Types.GET_CASES_FAILURE]: getCasesFailure,

  [Types.GET_CASE_DETAIL]: getCaseDetail,
  [Types.GET_CASE_DETAIL_SUCCESS]: getCaseDetailSuccess,
  [Types.GET_CASE_DETAIL_FAILURE]: getCaseDetailFailure,

  [Types.CREATE_CASE]: getCaseDetail,
  [Types.CREATE_CASE_SUCCESS]: getCaseDetailSuccess,
  [Types.CREATE_CASE_FAILURE]: getCaseDetailFailure,

  [Types.REDO_CASE_DETAIL]: redoCaseDetail,
  [Types.REDO_CASE_DETAIL_FAILURE]: redoCaseDetailFailure,
});

export const CaseActions = Creators;

const CaseSelector = (state) => state.case;

export const CaseSelectors = {
  getCurrentStep: createSelector(CaseSelector, (c) => c.currentStep),
  getRecordVideoData: createSelector(CaseSelector, (c) => c.recordVideoData),
  getRecord360VideoData: createSelector(
    CaseSelector,
    (c) => c.record360VideoData,
  ),
  getVinCaptureData: createSelector(CaseSelector, (c) => c.vinCaptureData),
  getOdoCaptureData: createSelector(CaseSelector, (c) => c.odoCaptureData),
  getDmgCaptureData: createSelector(CaseSelector, (c) => c.dmgCaptureData),
  getZoomInData: createSelector(CaseSelector, (c) => c.zoomInFramesData),
  currentCase: createSelector(CaseSelector, (c) => c.currentCase),
  getIsFullRecordVideoData: createSelector(
    CaseSelector,
    (c) => c.isFullRecordVideoData,
  ),
  getLog: createSelector(CaseSelector, (c) => c.log),
  getTransitionAndVideo: createSelector(
    CaseSelector,
    (c) => c.transitionAndVideo,
  ),
  getAppMode: createSelector(CaseSelector, (c) => c.appMode),
};
