import { all, call, delay, put, select } from 'redux-saga/effects';
import UploadService from '../../../../services/upload';
import api from '../../../../services/api';
import { CaseTypes } from '../../reducer/case';

const uploadService = UploadService;

export function* uploadImageToS3(params) {
  try {
    const response = yield call(uploadService.upload, params);
    return response;
    // eslint-disable-next-line
  } catch (ex: any) {
    console.log(
      'Debug ~ file: index.tsx:15 ~ function*uploadImageToS3 ~ ex:',
      ex,
    );
    yield delay(1000);
    // yield call(uploadImageToS3, params);
  }
}

export function* uploadLogToS3(params) {
  try {
    const response = yield call(uploadService.uploadLog, params);
    return response;
    // eslint-disable-next-line
  } catch (ex: any) {
    console.log(
      'Debug ~ file: index.tsx:30 ~ function*uploadImageToS3 ~ ex:',
      ex,
    );
    yield delay(1000);
    // yield call(uploadImageToS3, params);
  }
}

export function* uploadVideoToS3(params) {
  try {
    const response = yield call(uploadService.uploadVideo, params);
    return response;
    // eslint-disable-next-line
  } catch (ex: any) {
    console.log(
      'Debug ~ file: index.tsx:30 ~ function*uploadImageToS3 ~ ex:',
      ex,
    );
    yield delay(1000);
    // yield call(uploadImageToS3, params);
  }
}

export function* getAllCases() {
  try {
    const response = yield call(api.fetchCases);
    yield put({
      type: CaseTypes.GET_CASES_SUCCESS,
      payload: response.result,
    });
    // eslint-disable-next-line
  } catch (ex: any) {
    console.log('Debug ~ file: index.tsx:33 ~ function*getAllCases ~ ex:', ex);
    yield put({
      type: CaseTypes.GET_CASES_FAILURE,
      payload: ex.message,
    });
  }
}

export function* getCaseById({ id, callback = null }) {
  try {
    const response = yield call(api.fetchCaseById, id);
    yield put({
      type: CaseTypes.GET_CASE_DETAIL_SUCCESS,
      payload: response.result,
    });
    callback?.();
    // eslint-disable-next-line
  } catch (ex: any) {
    console.log('Debug ~ file: index.tsx:50 ~ function*getCaseById ~ ex:', ex);
    yield put({
      type: CaseTypes.GET_CASE_DETAIL_FAILURE,
      payload: ex.message,
    });
  }
}

export function* handleFinishCase() {
  try {
    const createCaseResponse = yield call(api.createCase);
    const { id } = createCaseResponse.result;

    const data = yield select((state) => state.case);

    const dataUpload = [];

    const recordData = data.recordVideoData.value;

    const record360Data = data.record360VideoData.value;

    const zoomInFramesData = data.zoomInFramesData.value;

    const logData = { caseID: id, log: data.log };

    const transitionData = data.transitionAndVideo.transition;

    const videoData = { caseID: id, video: data.transitionAndVideo.video };

    const appMode = data.appMode;

    if (data.recordVideoData.status === 'Completed')
      recordData.forEach((d) => {
        const { status, value } = d;
        if (status === 'success' && value) {
          const {
            image,
            bin,
            time,
            refImage,
            refTime,
            postRefImage,
            postRefTime,
          } = value;
          dataUpload.push({
            caseID: id,
            category: 'OVERVIEW_BIN',
            image,
            bin,
            time,
          });
          if (refImage) {
            dataUpload.push({
              caseID: id,
              category: 'OVERVIEW_BIN',
              overRef: true,
              image: refImage,
              bin,
              time: refTime,
            });
          }
          if (postRefImage) {
            dataUpload.push({
              caseID: id,
              category: 'OVERVIEW_BIN',
              overPostRef: true,
              image: postRefImage,
              bin,
              time: postRefTime,
            });
          }
        }
      });
    if (data.record360VideoData.status === 'Completed')
      record360Data.forEach((d) => {
        const { image, time } = d;
        dataUpload.push({
          caseID: id,
          category: 'OVERVIEW_360',
          image: image,
          time: time,
        });
      });
    if (data.vinCaptureData.status === 'Completed')
      dataUpload.push({
        caseID: id,
        category: 'VIN',
        image: data.vinCaptureData.value,
      });
    if (data.zoomInFramesData.status === 'Completed')
      zoomInFramesData.forEach((d) => {
        const { image, time, refTime, refImage, refBin } = d;
        dataUpload.push({
          caseID: id,
          category: 'ZOOMIN_TIME',
          image,
          time,
          refTime,
        });
        dataUpload.push({
          caseID: id,
          category: 'REF_TIME',
          image: refImage,
          refTime,
          time,
          bin: refBin,
        });
      });
    if (transitionData.length > 0) {
      transitionData.forEach((d) => {
        const { image, time, refTime } = d;
        dataUpload.push({
          caseID: id,
          category: 'TRANSITION',
          image,
          time,
          refTime,
        });
      });
    }
    if (data.odoCaptureData.status === 'Completed')
      dataUpload.push({
        caseID: id,
        category: 'ODOMETER',
        image: data.odoCaptureData.value,
      });
    const dmgImgZoomedIn = data.dmgCaptureData.value.zoomedIn;
    const dmgImgZoomedOut = data.dmgCaptureData.value.zoomedOut;
    if (data.dmgCaptureData.status === 'Completed') {
      if (dmgImgZoomedOut)
        dataUpload.push({
          caseID: id,
          category: 'OVERVIEW_DAMAGE',
          image: dmgImgZoomedOut,
        });
      if (dmgImgZoomedIn)
        dataUpload.push({
          caseID: id,
          category: 'CLOSEVIEW_DAMAGE',
          image: dmgImgZoomedIn,
        });
    }

    const uploadImageResponses = yield all(
      dataUpload.map((d) => call(uploadImageToS3, d)),
    );
    yield call(uploadVideoToS3, videoData);
    if (appMode === 'development') {
      yield call(uploadLogToS3, logData);
    }
    yield call(api.updateCaseImagesById, id, uploadImageResponses);
    yield call(api.updateCaseById, { id, status: 'INSPECTED' });
    yield put({
      type: CaseTypes.HANDLE_FINISH_CASE_SUCCESS,
    });
    // eslint-disable-next-line
  } catch (ex: any) {
    console.log(
      'Debug ~ file: index.tsx:144 ~ function*handleFinishCase ~ ex:',
      ex,
    );
    yield put({
      type: CaseTypes.HANDLE_FINISH_CASE_FAILURE,
      payload: ex.message,
    });
  }
}

export function* redoCaseById({ id }) {
  try {
    yield call(api.redoById, id);
    const response = yield call(api.fetchCaseById, id);
    yield put({
      type: CaseTypes.GET_CASE_DETAIL_SUCCESS,
      payload: response.result,
    });
    // eslint-disable-next-line
  } catch (ex: any) {
    console.log('Debug ~ file: index.tsx:50 ~ function*getCaseById ~ ex:', ex);
    yield put({
      type: CaseTypes.REDO_CASE_DETAIL_FAILURE,
    });
  }
}
